<template>
  <v-container class="fill-height secondary un-bg" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat class="elevation-3">
          <v-toolbar color="primary" dark flat>
            <v-img max-height="40" max-width="40" src="/logo.png" />
            <v-toolbar-title
              class="text-uppercase font-weight-bold subtitle-2"
              style="padding: 0 3px;"
              >UNDSS SRS</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" @submit.prevent v-model="valid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="model.firstName"
                    :counter="128"
                    :rules="rules.name"
                    :label="$t('user.firstName')"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="model.lastName"
                    :counter="128"
                    :rules="rules.name"
                    :label="$t('user.lastName')"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="model.username"
                    :counter="32"
                    :rules="rules.username"
                    :label="$t('user.username')"
                    append-icon="mdi-account-circle"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="model.email"
                    :rules="rules.email"
                    :label="$t('user.email')"
                    append-icon="mdi-at"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="model.position"
                    counter="50"
                    :label="$t('user.position')"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="model.phone"
                    :rules="rules.phone"
                    :label="$t('user.phone')"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="model.password"
                    :rules="rules.password"
                    :label="$t('user.password')"
                    name="password"
                    append-icon="mdi-lock"
                    type="password"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="model.confirmPassword"
                    :rules="rules.confirm"
                    :label="$t('user.confirmPassword')"
                    name="confirm"
                    append-icon="mdi-lock-check"
                    type="password"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    v-model="model.organizationId"
                    :items="sortItemsBy(getOrganizationNames(), 'name')"
                    item-text="name"
                    item-value="id"
                    :rules="rules.org"
                    :label="$t('user.organization')"
                    required
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" tile depressed @click="navigateToLogin">
              {{ $t("login.login") }}
            </v-btn>
            <v-spacer />
            <v-btn color="primary" tile depressed @click="tryRegister">
              {{ $t("login.register") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <copyright :size="64" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Copyright from "@/common/components/Copyright";

export default {
  name: "Register",
  components: { Copyright },
  data() {
    return {
      redirect: "/",
      valid: false,
      model: { email: "", username: "", password: "", organizationId: 0 },
      rules: {
        firstName: [
          (v) => !!v || "First name is required",
          (v) => (!!v && v.length >= 4) || "Name must be at least 4 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        lastName: [
          (v) => !!v || "Last name is required",
          (v) => (!!v && v.length >= 4) || "Name must be at least 4 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        username: [
          (v) => !!v || "Username is required",
          (v) =>
            (!!v && v.length >= 4) || "Username must be at least 4 characters",
          (v) =>
            (!!v && v.length <= 50) ||
            "Must be less than or eaqual to 50 characters",
          (v) => {
            const re = /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Username must start with an alphanumeric character, " +
                "can have a single (- or _) sepcial charactor " +
                "and/or any number of alphanumeric characters within the limit"
            );
          },
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (!!v && v.length >= 8) || "Password must be at least 8 characters",
          (v) => {
            const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Minimum eight characters, at least one letter, one number and one special character"
            );
          },
        ],
        confirm: [
          (v) => !!v || "Password is required",
          (v) =>
            v === this.model.password || "Password and confirmation must match",
        ],
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
            return re.test(String(v).toLowerCase()) || "Invalid email input";
          },
        ],
        phone: [
          (v) => !v || v.length >= 8 || "Not less than 8 numbers",
          (v) => {
            const re = /^[+\-/0-9 ]*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Only numbers, spaces and (+ - or /) sepcial charactors"
            );
          },
        ],
        org: [(v) => !!v || "Please select your organization"],
      },
    };
  },
  mounted() {
    this.loadOrganizations();
  },
  computed: {
    ...mapGetters("organizations", ["getOrganizationNames"]),
  },
  methods: {
    ...mapActions("organizations", ["loadOrganizations"]),
    ...mapActions("auth", ["register"]),

    tryRegister() {
      this.$refs.form.validate();

      setTimeout(() => {
        if (this.valid) {
          this.register(this.model).then((user) =>
            this.$router.push({
              name: "email",
              params: { name: user.name, email: user.email },
            })
          );
        }
      }, 300);
    },
    navigateToLogin() {
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>
